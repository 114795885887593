import { GiftPaymentModel } from '@/model/GiftPaymentModel';

export class RegisterPaymentModel {
    public firstName?: string;
    public lastName?: string;
    public email?: string;
    public areaCode?: string;
    public number?: string;
    public cpf?: string;
    public message?: string;
    public totalPayment?: number;
    public gifts?: GiftPaymentModel[] = [];
}