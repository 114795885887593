import { Inject } from "typescript-ioc";
import { HttpService } from "../configuration/HttpService";
import { ServiceBase } from "../configuration/ServiceBase";
import { Ambiente } from "../configuration/Ambiente";
import { Container } from "typescript-ioc";
import { RegisterPaymentModel } from "@/model/RegisterPaymentModel";
import { RegisterPaymentResponse } from "@/response/RegisterPaymentResponse";
import { CheckingStatusPaymentViewModel } from "@/response/view/CheckingStatusPaymentViewModel";

export class MercadoPay extends ServiceBase{
  constructor(@Inject http: HttpService) {
      super(http);
  }
  
  get ambiente(): Ambiente {
    return Container.get(Ambiente) as Ambiente;
  }

  public async getPayment(request: RegisterPaymentModel): Promise<any>{
    const urlParam = `${this.ambiente.obterUrlDoAmbiente()}registerPayment`;
    const resposta = await this.http.post<RegisterPaymentResponse>(urlParam, request);
    return resposta.data;
  }

  public async checkingPayment(idTransaction: number): Promise<CheckingStatusPaymentViewModel>{
    const resposta = await this.http
      .get(`${this.ambiente.obterUrlDoAmbiente()}statusPayment/${idTransaction}`);
    return resposta.data;
  }
}