import { Inject } from "typescript-ioc";
import { HttpService } from "../configuration/HttpService";
import { ServiceBase } from "../configuration/ServiceBase";
import { Ambiente } from "../configuration/Ambiente";
import { Container } from "typescript-ioc";
import { CoupleReportModel } from "@/model/CoupleReportModel";

export class CoupleReport extends ServiceBase{
  constructor(@Inject http: HttpService) {
      super(http);
  }
  
  get ambiente(): Ambiente {
    return Container.get(Ambiente) as Ambiente;
  }

  public async getCoupleReport(): Promise<CoupleReportModel>{
    const urlParam = `${this.ambiente.obterUrlDoAmbiente()}report`;
    const resposta = await this.http.get<CoupleReportModel>(urlParam);
    return resposta.data;
  }
}