import router from '@/router';
var jwt = require('jsonwebtoken');

export default (config: any)=> {
    var token = localStorage.getItem('tokenDashobard');
    
    if(token){
        const tokenExpirou = isTokenExpired(token);
        if(tokenExpirou){
            router.push({name: 'Login'});
        }
    }
    
    if (!config.headers.Authorization && token && config.url.indexOf('token') === -1) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
};

export const isTokenExpired = (token: string): boolean => {
    try {
        const { exp } = jwt.decode(token) as {
            exp: number;
        };
        const expirationDatetimeInSeconds = exp * 1000;
        return Date.now() >= expirationDatetimeInSeconds;
    } catch {
        return true;
    }
};