























import Vue from 'vue'
import { Component} from "vue-property-decorator";

@Component({
    components:{
    },
})

export default class TimeCount extends Vue{
    public days: string = "";
    public hours: string = "";
    public minutes: string = "";
    public seconds: string = "";

    public mounted() {
        this.startCountdown();
    }

    public startCountdown() {
        const countdown = () => {
            const now = new Date();
            const targetDate = new Date(2024, 9, 19, 20, 0, 0);
            const timeLeft = targetDate.getTime() - now.getTime();

            const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

            this.days = String(days).padStart(2, '0');
            this.hours = String(hours).padStart(2, '0');
            this.minutes = String(minutes).padStart(2, '0');
            this.seconds = String(seconds).padStart(2, '0');
        };

        countdown();
        setInterval(countdown, 1000);
    }
}
