





























































































import Vue from 'vue';
import { Component } from "vue-property-decorator";
import { VueMaskDirective } from 'v-mask';
import Vuetify from 'vuetify';
import { Access } from '@/service/configuration/Access';
import { CoupleReport } from '@/service/MercadoPay/CoupleReport';
import { Container } from 'typescript-ioc';
import { CoupleReportModel } from '@/model/CoupleReportModel';
Vue.directive('mask', VueMaskDirective);
import 'vuetify/dist/vuetify.min.css';
Vue.use(Vuetify);


@Component({
    components:{
    },
  })

export default class Casal extends Vue {

    public show: boolean= false;
    public coupleReport: CoupleReportModel = new CoupleReportModel;
    public colors: string[] =[
        'primary',
        'secondary',
        'yellow darken-2',
        'red',
        'orange',
      ]

    private get CoupleReport(): CoupleReport {
        return Container.get(CoupleReport) as CoupleReport;
    }

    public async mounted(){
        this.Login();
        this.coupleReport = await this.CoupleReport.getCoupleReport();
    }

    public Login(){
        const password: string | null = prompt('Controle de acesso. Digite a senha de acesso:');

        if (password === null) {
            alert('Login cancelado.');
            this.Login();
            return;
        }

        const access = new Access();
        const usuario: boolean | undefined = access.usuarios.includes(password);

        if (usuario) {
            alert(`Login bem sucedido! Bem-vindo.`);
        } else {
            alert('Senha incorretos. Tente novamente.');
            this.Login();
        }
        
    }

    public enviarMensagem(phone: string){
        const url = `https://api.whatsapp.com/send/?phone=${phone}&text&type=phone_number&app_absent=0`;
        return window.open(url, '_blank');
    }

    public formatMoney(numero: any) {
        const numeroFormatado = numero.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        return numeroFormatado
    }
}
