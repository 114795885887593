export class Ambiente{
    
    public local: string = "https://mercadopagopayment-cd0a48b12b74.herokuapp.com/";
    public producao: string = "https://mercadopagopayment-cd0a48b12b74.herokuapp.com/";


    public obterUrlDoAmbiente(): string {
        if (process.env.NODE_ENV == "production") {
            return this.producao;
        }
        return this.local;
    }
}