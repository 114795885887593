import { Inject } from "typescript-ioc";
import { HttpService } from "../configuration/HttpService";
import { ServiceBase } from "../configuration/ServiceBase";
import { Ambiente } from "../configuration/Ambiente";
import { Container } from "typescript-ioc";
import { GiftModel } from "@/model/GiftModel";

export class Gift extends ServiceBase{
  constructor(@Inject http: HttpService) {
      super(http);
  }
  
  get ambiente(): Ambiente {
    return Container.get(Ambiente) as Ambiente;
  }

  public async getGiftList(): Promise<GiftModel[]>{
    const urlParam = `${this.ambiente.obterUrlDoAmbiente()}giftlist`;
    const resposta = await this.http.get<GiftModel[]>(urlParam);
    return resposta.data;
  }
}