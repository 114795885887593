


















































































































































































































































































































































































































import Vue from 'vue';
import { Component, Watch } from "vue-property-decorator";
import Payment from '@/components/Payment.vue';
import Convite from '@/components/Convite.vue';
import TimeCount from '@/components/TimeCount.vue';
import { VueMaskDirective } from 'v-mask'
import ConfirmacaoDePresenca from '@/components/ConfirmacaoDePresenca.vue';
import Vuetify from 'vuetify'
import { Container } from 'typescript-ioc';
import { Gift } from '@/service/MercadoPay/Gift';
import { GiftModel } from '@/model/GiftModel';
Vue.directive('mask', VueMaskDirective);
import 'vuetify/dist/vuetify.min.css'
Vue.use(Vuetify)


@Component({
    components:{
        Payment,
        Convite,
        ConfirmacaoDePresenca,
        TimeCount,
    },
  })

export default class Home extends Vue {
    public itemsPerPage: number = 9;
    public currentPage: number = 1;
    public quantityItems: number = 0;

    public showButton:boolean = false;
    public snackbar: boolean = false;

    public giftList: GiftModel[] = [];
    public giftListCart: GiftModel[] = [];

    public ordenacao: string = 'Mais relevantes';
    public corSnackbar:string = 'green';
    public textSnackbar: string='Adicionado ao carrinho.';

    public galerias: string[] =
    [
        'https://i.imgur.com/czqAHXS.jpeg',
        'https://i.imgur.com/htDgzsm.jpeg',
        'https://i.imgur.com/jHdYwuT.jpeg',
        'https://i.imgur.com/3nfsO75.jpeg',
        'https://i.imgur.com/K4iGOFW.jpeg',
        'https://i.imgur.com/I7AwrXC.jpeg',
        'https://i.imgur.com/B4Lifo6.jpeg',
        'https://i.imgur.com/uwYt9jy.jpeg',
        'https://i.imgur.com/TC5FED0.jpeg',
        'https://i.imgur.com/hdM6e6G.jpeg',
        'https://i.imgur.com/VjJWX0E.jpeg'
    ];

    //Booleano para habilitar formulario
    public habilitarGiftList: boolean = true;
    //Booleano para habilitar formulario
    public habilitarCarrinho: boolean = false;
    

    public get paginatedGiftList() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.giftList.slice(startIndex, endIndex);
    }

    public get totalPages() {
      return Math.ceil(this.giftList.length / this.itemsPerPage);
    }

    public obterCarrinho(): GiftModel[] {
        const carrinhoString = localStorage.getItem('carrinhoGift');
        return carrinhoString ? JSON.parse(carrinhoString) : [];
    }

    public salvarCarrinho(carrinho: GiftModel[]): void {
        localStorage.setItem('carrinhoGift', JSON.stringify(carrinho));
    }

    public removerItemGiftCart(index: number){
        this.giftListCart.splice(index,1);
        return this.salvarCarrinho(this.giftListCart);
    }

    public somarTotalCarrinho(){
        const total = this.giftListCart.reduce((total, item) => total + item.amount!, 0);
        if(total === undefined){
            return 0;
        }
        return total;
    }

    public habilitarViewCarrinho(){
        this.habilitarGiftList = false;
        this.habilitarCarrinho = true;
    }

    public adicionarMaisItems(){
        this.habilitarCarrinho = false;
        this.habilitarGiftList = true;
    }

    public async adicionarAoCarrinho(gift: GiftModel){
        this.snackbar = true;
        await this.giftListCart.push(gift);
        this.salvarCarrinho(this.giftListCart);
    }

    public ordenarLista() {
      if(this.ordenacao === 'Mais relevantes'){
        this.giftList = this.giftList.sort((a, b) => a.id! - b.id!);
        this.currentPage = 1;
      }

      if(this.ordenacao === 'Menor preço'){
        this.giftList = this.giftList.sort((a, b) => a.amount! - b.amount!);
        this.currentPage = 1;
      }

      if(this.ordenacao === 'Maior preço'){
        this.giftList = this.giftList.sort((a, b) => b.amount! - a.amount!);
        this.currentPage = 1;
      }
    }

    private get Gift(): Gift {
        return Container.get(Gift) as Gift;
    }

    public async mounted() {
        window.addEventListener("scroll", this.handleScroll);
        this.giftList = await this.Gift.getGiftList();
        this.giftListCart = this.obterCarrinho();
    }

    public scrollItens(item: string) {
        const elementoAlvo = this.$refs[item] as HTMLElement;
        if (elementoAlvo) {
        elementoAlvo.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    public scrollFabCart() {
        this.habilitarGiftList = false;
        this.habilitarCarrinho = true;
        const elementoAlvo = this.$refs['pedidos'] as HTMLElement;
        if (elementoAlvo) {
        elementoAlvo.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    public beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    }
    public handleScroll() {
        this.showButton = window.scrollY > 100; // Exibir botão quando o usuário rolar 100 pixels para baixo
    }
    public scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth" // Rolagem suave até o topo
        });
    }

    public formatMoney(numero: any) {
        const numeroFormatado = numero.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        return numeroFormatado
    }

    public confirmarPagamento(){
        this.giftListCart = [];
        localStorage.setItem('carrinhoGift', JSON.stringify(this.giftListCart));
        this.habilitarCarrinho = false;
        this.habilitarGiftList = true;
    }
    

    @Watch('giftListCart')
    public listenningGiftListCart(){
        this.quantityItems = this.giftListCart.length;
    }
}
