export class CoupleReportModel {
    public paymentTotal?: number;
    public paymentAccept?: number;
    public paymentPending?: number;
    public coupleReportClients?: CoupleReportClientModel[];
}

export class CoupleReportClientModel {
    public idTransaction?: number;
    public status?: string;
    public name?: string;
    public phone: string = '';
    public message?: string;
    public totalAmount?: number;
    public coupleReportGifts?: CoupleReportGiftModel[];
}

export class CoupleReportGiftModel {
    public idTransaction?: number;
    public image?: number;
    public product?: string;
    public amount?: number;
}