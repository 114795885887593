import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from "@/pages/Home.vue";
import Casal from "@/pages/Casal.vue";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes:[
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/casal',
      name: 'Casal',
      component: Casal
    },
  ]
})

export default router
